import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import "../assets/css/main.css";
import Brick from "../components/bricks/bricks";
import { getAlternateLang } from "../utils/alternate-lang";
import BrickImageText from "../components/bricks/image-text";
import { GatsbyImage } from "gatsby-plugin-image";
import ListOfCards from "../components/cards/list-of-cards";
import CardLink from "../components/cards/card-link";
import CustomButtonText from "../components/button/custom-button";
import BrickNewsletter from "../components/bricks/newsletter";
import { useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { gtmPushUserInfo } from "../services/auth";

const IndexPage = ({ data }) => {
  const { language, t } = useI18next();
  const dataLanding =
    language === "en" ? data.strapiLandingEn : data.strapiLandingEs;
  const {
    headerImage,
    pageContent,
    ourLocationMap,
    ourLocationMapMobile,
    pageContentFullWidthImages,
    pageContentConstrainedImages,
    pageContentSeeMoreThumbs,
    pageContentSeeMoreEventsThumbs,
    pageContentSeeMoreCoursesThumbs,
  } = dataLanding;
  const seo = {
    metaTitle: t("metaData.home.metaTitle"),
    metaDescription: t("metaData.home.metaDescription"),
    linkAlternateHref: "/" + getAlternateLang(language),
    linkHrefLang: getAlternateLang(language),
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Home",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
    window.gtag("event", "conversion", {
      send_to: `${process.env.GATSBY_GTAG_ID}/${process.env.GATSBY_GTAG_DEST}`,
    });
  }, [language]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const therapeuticAreas =
    language === "en"
      ? data.therapeuticAreaEn.edges
      : data.therapeuticAreaEs.edges;
  const [seeHowMany, setSeeHowMany] = useState(3);
  const newsletterBlock = dataLanding.pageContent
    .map((content, i) => {
      return { content: content, index: i };
    })
    .find(
      (block) =>
        block.content.strapi_component === "content.image-title-subtitle-button"
    );
  const onLoadMore = () => {
    setSeeHowMany((previousValue) => previousValue + 3);
  };
  const getCardFromTherapeuticalArea = (therapeuticArea) => {
    return (
      <CardLink
        title={therapeuticArea.node.Title}
        type="therapeutic-area"
        description=""
        image={
          <GatsbyImage
            image={
              therapeuticArea.node.Thumbnail.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={
              therapeuticArea.node.Thumbnail.alternativeText
                ? therapeuticArea.node.Thumbnail.alternativeText
                : ""
            }
          />
        }
        date=""
        labelText=""
        labelColor=""
        link={`/medical-informations/${therapeuticArea.node.Slug}`}
        borderColor={therapeuticArea.node.therapeutical_area.color}
        therapeuticAreaLabel={therapeuticArea.node.therapeutical_area.Name}
        therapeuticAreaColor={therapeuticArea.node.therapeutical_area.color}
        centeredText={true}
      />
    );
  };

  const getImageContent = (i) => {
    return pageContentFullWidthImages[i]?.localFile !== null
      ? pageContentFullWidthImages[i]
      : pageContentConstrainedImages[i];
  };

  return (
    <Layout seo={seo}>
      {headerImage && (
        <BrickImageText
          title=""
          gatsbyImageData={
            headerImage.localFile.childImageSharp.gatsbyImageData
          }
          alternativeText={headerImage.alternativeText}
          mobileImageStyle={{
            maxHeight: "200px",
          }}
          isHeader={true}
        />
      )}
      {pageContent.map((content, i) => {
        if (
          content.strapi_component !== "content.image-title-subtitle-button"
        ) {
          return (
            <Brick
              key={`content__${i}`}
              content={content}
              seeMoreCoursesThumbsContent={pageContentSeeMoreCoursesThumbs[i]}
              imageContent={getImageContent(i)}
              seeMoreThumbsContent={pageContentSeeMoreThumbs[i]}
              seeMoreEventsThumbsContent={pageContentSeeMoreEventsThumbs[i]}
              isMainHomeVideo={true}
            />
          );
        }
        return null;
      })}
      <ListOfCards
        title={t("therapeuticArea")}
        cards={therapeuticAreas
          .slice(0, seeHowMany)
          .map((therapeuticArea) =>
            getCardFromTherapeuticalArea(therapeuticArea)
          )}
        button={
          therapeuticAreas.length > seeHowMany ? (
            <CustomButtonText
              text="Load More"
              onClick={onLoadMore}
              style={{
                letterSpacing: "0.1em",
              }}
            />
          ) : (
            <></>
          )
        }
        backgroundColor="#FFFFFF"
      />
      <BrickNewsletter
        title={newsletterBlock.content.title}
        subtitle={newsletterBlock.content.subtitle}
        buttonText={newsletterBlock.content.buttonText}
        backgroundImageData={
          getImageContent(newsletterBlock.index).localFile.childImageSharp
            .gatsbyImageData
        }
        alternativeText={newsletterBlock.content.background.alternativeText}
        style={{ padding: 0 }}
      />
      <div style={{ position: "relative" }}>
        <BrickImageText
          title=""
          gatsbyImageData={
            ourLocationMap.localFile.childImageSharp.gatsbyImageData
          }
          alternativeText={ourLocationMap.alternativeText}
          mobileImageStyle={{
            maxHeight: false,
            borderRadius: "",
          }}
          mobileGatsbyImageData={
            ourLocationMapMobile.localFile.childImageSharp.gatsbyImageData
          }
          isMap={true}
        />
        <Typography
          variant="ourLocations"
          style={{
            position: "absolute",
            width: "100%",
            bottom: "85%",
          }}
        >
          {t("home.ourLocations")}
        </Typography>
      </div>
    </Layout>
  );
};

export const query = graphql`
  fragment StrapiLandingFragment on StrapiLanding {
    pageContent
    headerImage {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ourLocationMap {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    ourLocationMapMobile {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    pageContentConstrainedImages {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    pageContentFullWidthImages {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    pageContentSeeMoreThumbs {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 276, height: 223)
        }
      }
    }
    pageContentSeeMoreEventsThumbs {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 276, height: 223)
        }
      }
    }
    pageContentSeeMoreCoursesThumbs {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 276, height: 223)
        }
      }
    }
  }

  fragment allStrapiEditorialContentFragment on StrapiEditorialContentConnection {
    edges {
      node {
        id
        strapiId
        localizations {
          id
        }
        Title
        Slug
        Description
        date(formatString: "MMMM DD, YYYY")
        therapeutical_area {
          Name
          Slug
          color
        }
        Thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 276, height: 223)
            }
          }
        }
        editorial_content_type {
          Name
          Slug
          color
        }
      }
    }
  }

  query Landing($language: String!) {
    strapiLandingEn: strapiLanding(locale: { eq: "en" }) {
      ...StrapiLandingFragment
    }
    strapiLandingEs: strapiLanding(locale: { eq: "es-PA" }) {
      ...StrapiLandingFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    therapeuticAreaEn: allStrapiEditorialContent(
      filter: {
        editorial_content_type: { Slug: { eq: "disease-awareness" } }
        locale: { eq: "en" }
      }
      sort: { order: DESC, fields: [date] }
    ) {
      ...allStrapiEditorialContentFragment
    }
    therapeuticAreaEs: allStrapiEditorialContent(
      filter: {
        editorial_content_type: { Slug: { eq: "disease-awareness" } }
        locale: { eq: "es-PA" }
      }
      sort: { order: DESC, fields: [date] }
    ) {
      ...allStrapiEditorialContentFragment
    }
  }
`;

export default IndexPage;
